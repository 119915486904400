import {
  HeadingText,
  ParagraphText,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { BasePage } from 'components/BasePage';
import { OnboardingMachineContext } from 'components/onboarding/onboarding.machine';
import { CTAFooter } from 'components/cta-footer/cta-footer.component';
import CompleteIllustration from 'assets/complete-illustration.svg';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  trackAnalyticsEvent,
  usePageViewAnalytics,
} from '@leagueplatform/analytics';
import {
  DETAIL,
  SCREEN_NAMES,
  SUB_PRODUCT_AREA,
} from 'common/constants/analytics';

export const AllSetView = () => {
  const { send } = OnboardingMachineContext.useActorRef();
  const { formatMessage } = useIntl();
  const handleContinue = () => send({ type: 'userSelectedContinue' });

  usePageViewAnalytics({
    product_area: PRODUCT_AREA.ONBOARDING,
    sub_product_area: SUB_PRODUCT_AREA.ALL_SET,
    screen_name: SCREEN_NAMES.ALL_SET,
  });

  const trackCTAButtonClick = () => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.ONBOARDING,
      sub_product_area: SUB_PRODUCT_AREA.ALL_SET,
      screen_name: SCREEN_NAMES.ALL_SET,
      detail: DETAIL.CONTINUE,
    });
  };

  return (
    <BasePage isPrivatePage={false}>
      <StackLayout
        spacing="$oneAndHalf"
        verticalAlignment="center"
        horizontalAlignment="center"
        css={{
          justifySelf: 'center',
          maxWidth: 'calc(327px + $one)',
          paddingInline: '$one',
          paddingBlockStart: '$two',
          paddingBlockEnd: 'calc(100px + $two)',
        }}
      >
        <img src={CompleteIllustration} alt="" />
        <HeadingText level="1" size="xl">
          {formatMessage({ id: 'STR_ALL_SET_HEADING' })}
        </HeadingText>
        <ParagraphText css={{ textAlign: 'center' }}>
          {formatMessage({ id: 'STR_ALL_SET_BODY' })}
        </ParagraphText>
      </StackLayout>
      <CTAFooter
        showPrimaryBtn
        primaryBtnLabel={formatMessage({ id: 'STR_CONTINUE' })}
        primaryBtnProps={{
          onClick: () => {
            trackCTAButtonClick();
            handleContinue();
          },
        }}
      />
    </BasePage>
  );
};
