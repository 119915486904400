import {
  HeadingText,
  StackLayout,
  ParagraphText,
  FormField,
  TextInput,
  InputStatusMessage,
} from '@leagueplatform/genesis-core';
import { isEmail } from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';
import { TOAST_STATUS, toastActions } from '@leagueplatform/toast-messages';
import { useFormik, type FormikErrors } from 'formik';
import { useErrorBoundary } from 'react-error-boundary';
import { BasePage } from 'components/BasePage';
import { OnboardingMachineContext } from 'components/onboarding/onboarding.machine';
import SelfIllustration from 'assets/self-illustration.svg';
import { CTAFooter } from 'components/cta-footer/cta-footer.component';
import { CHARACTER_LIMIT } from 'common/constants';
import { isEmailAlreadyExistError } from 'utils/is-email-already-exist-error';
import { isValidPreferredName } from 'utils/is-valid-preferred-name/is-valid-preferred-name';
import { useUpdateUserProfile } from 'api/update-user-profile';
import { useUpdateUserEmail } from 'hooks/use-update-user-email/use-update-user-email.hook';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  SCREEN_NAMES,
  SUB_PRODUCT_AREA,
  trackAnalyticsEvent,
  usePageViewAnalytics,
} from '@leagueplatform/analytics';
import { DETAIL } from 'common/constants/analytics';

type FormValues = {
  displayName: string;
  emailAddress: string;
};

export const CreateProfileView = () => {
  const { formatMessage } = useIntl();
  const { send } = OnboardingMachineContext.useActorRef();
  const context = OnboardingMachineContext.useSelector(
    (snapshot) => snapshot.context,
  );
  const updateUserProfileMutation = useUpdateUserProfile();
  const updateUserEmailMutation = useUpdateUserEmail();
  const { showBoundary } = useErrorBoundary();

  const formik = useFormik({
    initialValues: {
      displayName: context.displayName,
      emailAddress: context.email,
    },
    validate: (values) => {
      const errors: FormikErrors<FormValues> = {};
      if (values?.displayName !== undefined) {
        // Display Name validation
        if (values.displayName.length > CHARACTER_LIMIT) {
          errors.displayName = formatMessage({
            id: 'STR_ERROR_DISPLAY_NAME_TOO_LONG',
          });
        } else if (!isValidPreferredName(values.displayName)) {
          errors.displayName = formatMessage({
            id: 'STR_ERROR_DISPLAY_NAME_ONLY_LETTERS',
          });
        }
      }

      // Email Address validation
      if (!values.emailAddress) {
        errors.emailAddress = formatMessage({ id: 'STR_REQUIRED_FIELD' });
      } else if (!isEmail(values.emailAddress)) {
        errors.emailAddress = formatMessage({
          id: 'STR_INVALID_EMAIL_ADDRESS',
        });
      }

      return errors;
    },
    onSubmit: async (values) => {
      if (formik.isValid) {
        if (formik.dirty) {
          try {
            if (values?.displayName !== undefined) {
              await updateUserProfileMutation.mutateAsync({
                preferred_first_name: values.displayName,
              });
            }

            updateUserEmailMutation.mutateAsync(
              {
                emailAddress: values.emailAddress,
              },
              {
                onSuccess() {
                  send({ type: 'userConfirmedProfile' });
                },
                onError(error) {
                  if (
                    error instanceof Error &&
                    isEmailAlreadyExistError(error)
                  ) {
                    toastActions.add({
                      type: TOAST_STATUS.ERROR,
                      textId:
                        'STR_EXCEPTION_EMAIL_FAILED_TO_SAVE_ALREADY_EXIST',
                    });
                  } else {
                    toastActions.add({
                      type: TOAST_STATUS.ERROR,
                      textId: 'STR_EXCEPTION_EMAIL_FAILED_TO_SAVE',
                    });
                  }
                },
              },
            );
          } catch (e) {
            showBoundary(e);
          }
        } else {
          send({ type: 'userConfirmedProfile' });
        }
      }
    },
  });

  usePageViewAnalytics({
    product_area: PRODUCT_AREA.ONBOARDING,
    sub_product_area: SUB_PRODUCT_AREA.ACCOUNT_SET_UP,
    screen_name: SCREEN_NAMES.ACCOUNT_SET_UP,
  });

  const trackCTAButtonClick = () => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.ONBOARDING,
      sub_product_area: SUB_PRODUCT_AREA.ACCOUNT_SET_UP,
      screen_name: SCREEN_NAMES.ACCOUNT_SET_UP,
      detail: DETAIL.CONTINUE,
    });
  };

  return (
    <BasePage isPrivatePage={false}>
      <StackLayout
        spacing="$oneAndHalf"
        verticalAlignment="center"
        horizontalAlignment="center"
        css={{
          justifySelf: 'center',
          maxWidth: 'calc(327px + $one)',
          paddingInline: '$one',
          paddingBlockStart: '$two',
          paddingBlockEnd: 'calc(100px + $two)',
        }}
      >
        <img src={SelfIllustration} alt="" />

        <StackLayout
          css={{ flexGrow: 0 }}
          verticalAlignment="center"
          horizontalAlignment="center"
          spacing="$half"
        >
          <HeadingText level="1" size="xl">
            {formatMessage({ id: 'STR_CREATE_PROFILE_HEADING' })}
          </HeadingText>
          <ParagraphText css={{ textAlign: 'center' }}>
            {formatMessage({ id: 'STR_CREATE_PROFILE_BODY' })}
          </ParagraphText>
        </StackLayout>
        <StackLayout
          as="form"
          onSubmit={(e: React.FormEvent<HTMLFormElement>) =>
            formik.handleSubmit(e)
          }
          spacing="$one"
          css={{ flexGrow: 0, alignSelf: 'stretch' }}
        >
          <FormField
            id="displayName"
            label={formatMessage({ id: 'STR_DISPLAY_NAME' })}
            required={false}
          >
            <TextInput
              name="displayName"
              onChange={formik.handleChange}
              value={formik.values.displayName}
              maxLength={CHARACTER_LIMIT}
              aria-describedby={
                formik.errors.displayName
                  ? 'displayNameErrorMessage'
                  : undefined
              }
            />
            {formik.errors.displayName && (
              <InputStatusMessage
                inputStatus="error"
                id="displayNameErrorMessage"
                statusIconLabel="Error"
              >
                {formik.errors.displayName}
              </InputStatusMessage>
            )}
          </FormField>
          <FormField
            id="emailAddress"
            label={formatMessage({ id: 'STR_EMAIL_ADDRESS' })}
          >
            <>
              <TextInput
                name="emailAddress"
                onChange={formik.handleChange}
                value={formik.values.emailAddress}
                aria-describedby={
                  formik.errors.emailAddress
                    ? 'emailAddressErrorMessage'
                    : undefined
                }
              />
              {formik.errors.emailAddress && (
                <InputStatusMessage
                  inputStatus="error"
                  id="emailAddressErrorMessage"
                  statusIconLabel="Error"
                >
                  {formik.errors.emailAddress}
                </InputStatusMessage>
              )}
            </>
          </FormField>

          <CTAFooter
            showPrimaryBtn
            primaryBtnLabel={formatMessage({ id: 'STR_CONTINUE' })}
            primaryBtnProps={{
              onClick: trackCTAButtonClick,
              type: 'submit',
              loading: updateUserProfileMutation.isLoading,
            }}
          />
        </StackLayout>
      </StackLayout>
    </BasePage>
  );
};
