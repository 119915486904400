import {
  StackLayout,
  UtilityText,
  Box,
  Button,
} from '@leagueplatform/genesis-core';
import { HtmlToReact } from '@leagueplatform/web-common';
import {
  MasonryEngineNodeRendererProps,
  type MasonryEngineNode,
} from '@leagueplatform/masonry-engine';
import { PageHeader } from 'components/page-header/page-header.component';
import { NotificationTypeBadge } from 'components/notifications/notification-type-badge.component';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hook';
import { NotificationTypes } from 'common/types';
import { useConfigProperty } from '@leagueplatform/core';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';
import { DETAIL, SCREEN_NAMES } from 'common/constants/analytics';

type KeyValueType = Record<'key' | 'value', string>;

type NotificationDetailsNodeProperties = {
  heading: string;
  image: string;
  badgeText: string;
  notificationType: NotificationTypes;
  items: KeyValueType[];
  footerText: string;
  footerActionLink: string;
  footerButtonTitle: string;
};

type NotificationDetailsNode = MasonryEngineNode<
  'ovatientNotificationDetailsCard',
  NotificationDetailsNodeProperties
>;

type NotificationDetailsProps =
  MasonryEngineNodeRendererProps<NotificationDetailsNode>;

export const NotificationDetailsRenderer = ({
  heading,
  image,
  badgeText,
  notificationType,
  items,
  footerText,
  footerActionLink,
  footerButtonTitle,
}: NotificationDetailsProps) => {
  const isBelowTablet = useIsBelowTablet();

  const handleLinkCallback = useConfigProperty('core.customMethods.handleLink');

  const trackCTAButtonClick = () => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.GET_CARE,
      screen_name: SCREEN_NAMES.NOTIFICATION_DETAIL_PAGE,
      detail: DETAIL.SELECT_CTA,
      heading: 'Manage in MyChart',
      content: {
        id: 'notificationdetailpage clicked',
        name: 'notification clicked',
        system: 'dynamic',
      },
    });
  };

  return (
    <>
      <PageHeader text={heading} historyGoBack />
      <Box css={{ marginInline: isBelowTablet ? '$none' : '$one' }}>
        <StackLayout
          id="container"
          horizontalAlignment="stretch"
          spacing="$two"
          css={{
            maxWidth: 948,
            height: '100%',
            paddingBlock: '$two',
            paddingInline: '$two',
            marginInline: 'auto',
            ...(isBelowTablet
              ? {}
              : {
                  border: '1px solid $onSurfaceBorderSubdued',
                  borderRadius: '9px',
                }),
          }}
        >
          <StackLayout
            horizontalAlignment="center"
            spacing="$threeQuarters"
            css={{ alignSelf: 'center' }}
          >
            <img src={image} alt="" width={98} />
            <NotificationTypeBadge label={badgeText} type={notificationType} />
          </StackLayout>
          <Box
            css={{
              display: 'grid',
              gridTemplateColumns: isBelowTablet ? '1fr' : '1fr 1fr',
              gap: '$one',
            }}
          >
            {items.map((item: KeyValueType) => (
              <Box css={{ flexBasis: 'calc(50% - $two)' }}>
                <UtilityText
                  css={{
                    typography: '$overline',
                    color: '$onSurfaceTextSubdued',
                  }}
                >
                  {item.key}
                </UtilityText>
                <UtilityText css={{ display: 'block' }}>
                  <HtmlToReact htmlString={item.value.replace(/\n/g, '<br>')} />
                </UtilityText>
              </Box>
            ))}
          </Box>

          <StackLayout spacing="$oneAndHalf" horizontalAlignment="stretch">
            <UtilityText css={{ typography: '$bodyTwo' }}>
              {footerText}
            </UtilityText>
            <Box
              css={{
                alignSelf: 'center',
                maxWidth: 334,
                width: '100%',
              }}
            >
              <Button
                as="a"
                onClick={(e: React.MouseEvent) => {
                  e?.preventDefault();
                  trackCTAButtonClick();
                  handleLinkCallback(footerActionLink, '_blank');
                }}
                href={footerActionLink}
                indicator="externalLink"
                priority="secondary"
                target="_blank"
                width="fillContainer"
              >
                {footerButtonTitle}
              </Button>
            </Box>
          </StackLayout>
        </StackLayout>
      </Box>
    </>
  );
};
