import { Suspense, useMemo, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ErrorBoundary } from 'react-error-boundary';
import { useOnlineStatus } from '@leagueplatform/web-common';
import { FullPageLoadingSpinner } from 'components/loaders/full-page-loading-spinner.component';
import League, { LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { config } from 'init-config';
import { Modal } from '@leagueplatform/genesis-core';
import { ErrorTypes } from 'hooks/use-error-message.hook';
import { SignInIntegrationModal } from 'components/modals/sign-in-integration-modal.component';
import { MY_CHART_FIRST_CLICKED_KEY } from 'common/constants';
import AppRoutes from './components/AppRoutes';
import { FullPageError } from './components/error/full-page-error.component';

League.overwriteRoutePaths({
  [LEAGUE_MODULE_NAMES.wallet]: '/benefits',
  [LEAGUE_MODULE_NAMES.careDocuments]: '/care/documents',
  [LEAGUE_MODULE_NAMES.messaging]: '/messaging',
});

export function App() {
  const [urlForModal, setUrlForModal] = useState<string | null>(null);

  const configWithLinkHandler = useMemo(() => {
    const configCopy = { ...config };

    const originalHandleLink = configCopy.core.customMethods.handleLink;

    configCopy.core.customMethods.handleLink = async (url, target) => {
      const myChartUrl = url.match(/mychart/i) || url.match(/myChartTST/i);

      const skipLoginPrompt = url.match(/login-prompt=false/i);

      const myChartAlreadyClicked = localStorage.getItem(
        MY_CHART_FIRST_CLICKED_KEY,
      );

      if (myChartUrl && !skipLoginPrompt && !myChartAlreadyClicked) {
        setUrlForModal(url);
      } else {
        originalHandleLink(url, target);
      }
    };

    return configCopy;
  }, []);

  const trackMyChartFirstClick = () => {
    localStorage.setItem(MY_CHART_FIRST_CLICKED_KEY, 'true');
  };

  return (
    <BrowserRouter>
      <CompatRouter>
        <League.LeagueProvider config={configWithLinkHandler}>
          <ErrorBoundary
            fallback={
              <FullPageError
                errorType={ErrorTypes.API_ERROR}
                buttonMessageId="STR_RELOAD"
                buttonOnClick={() => window.location.reload()}
              />
            }
          >
            <Suspense fallback={<FullPageLoadingSpinner />}>
              {useOnlineStatus().isOffline && (
                <FullPageError errorType={ErrorTypes.NO_CONNECTION} />
              )}
              {useOnlineStatus().isOnline && (
                <>
                  <Modal.Root
                    open={!!urlForModal}
                    onOpenChange={(isOpen) => {
                      if (!isOpen) {
                        setUrlForModal(null);
                      }
                    }}
                  >
                    {urlForModal && (
                      <SignInIntegrationModal
                        url={urlForModal}
                        onSubmit={() => {
                          setUrlForModal(null);
                          trackMyChartFirstClick();
                        }}
                      />
                    )}
                  </Modal.Root>
                  <AppRoutes />
                </>
              )}
              <ReactQueryDevtools />
            </Suspense>
          </ErrorBoundary>
        </League.LeagueProvider>
      </CompatRouter>
    </BrowserRouter>
  );
}
