import { useLocation, Redirect } from 'react-router-dom';

export const MemberPathRedirect = () => {
  const location = useLocation();
  return (
    <Redirect
      to={{
        ...location,
        // Removes first path string before non character value (/, ?, etc), e.g. /old-path/same -> /same
        pathname: location.pathname.replace(/^\/[\w-]+/, ''),
      }}
    />
  );
};
