import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '@leagueplatform/auth-utils';
import { useAnalyticsIdentify } from '@leagueplatform/web-common';
import { FullPageLoadingSpinner } from 'components/loaders/full-page-loading-spinner.component';
import { TENANT_ID } from 'common/constants';

export const AuthSessionContainer = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const history = useHistory();
  const { isAuthenticated, error } = useAuth();
  useAnalyticsIdentify({ employer_group_id: '', tenant_id: TENANT_ID });
  const location = useLocation();

  if (isAuthenticated === undefined) {
    return <FullPageLoadingSpinner />;
  }

  if (error || !isAuthenticated) {
    history.push({
      pathname: '/',
      state: {
        /**
         * Keep track of the page the user landed on so that after auth is
         * complete we can redirect them back to the deeplink.
         */
        redirectPath: location.pathname,
      },
    });
    return null;
  }

  return children;
};
