import { useScrollTopOnLocationChange } from '@leagueplatform/web-common';
import * as React from 'react';
import { Box, GDSStyleObject } from '@leagueplatform/genesis-core';
import { LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { useRouteMatch } from 'react-router-dom';
import { AppNavBarComponent } from 'components/header-nav/navbar/nav.component';
import { SDK_CSS_OVERRIDES } from 'common/ui';
import { GlobalFooter } from './global-footer/global-footer.component';

type BasePageProps = React.PropsWithChildren<{
  css?: GDSStyleObject;
  isPrivatePage?: boolean;
}>;

export const BasePage: React.FC<BasePageProps> = ({
  children,
  isPrivatePage = true,
  css,
}) => {
  useScrollTopOnLocationChange();

  const hideFooter = useRouteMatch({
    path: [
      `/${LEAGUE_MODULE_NAMES.healthJourney}/activity/`,
      `/${LEAGUE_MODULE_NAMES.healthJourney}/activity/:userActivityId/toolbox`,
      `/${LEAGUE_MODULE_NAMES.healthJourney}/campaign/:campaignId/activity/:activityId/toolbox`,
    ],
  });

  return (
    <Box
      css={{
        height: '100vh',
        display: 'grid',
        gridTemplateColumns: '100%',
        gridTemplateRows: isPrivatePage ? 'auto 1fr auto' : '1fr',
        overflowInline: 'hidden',
      }}
    >
      {isPrivatePage && <AppNavBarComponent />}
      <Box
        as="main"
        css={{
          position: 'relative',
          paddingBlockEnd: '$three',
          ...SDK_CSS_OVERRIDES,
          ...css,
        }}
      >
        {children}
      </Box>
      {isPrivatePage && !hideFooter && <GlobalFooter />}
    </Box>
  );
};
