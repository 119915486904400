import md5 from 'md5';
import { getUserProfile } from '@leagueplatform/user-profile-api';
import { fromPromise, setup, assign } from 'xstate';
import { createActorContext } from '@xstate/react';
import { LOCAL_STORAGE_KEYS } from 'common/constants';
import { localStorageItemExists } from 'utils/local-storage-item-exists.util';

type OnboardingMachineContextType = {
  termsAccepted: boolean;
  featureHighlightCompleted: boolean;
  userId: string;
  email: string;
  displayName: string;
};

export const onboardingMachine = setup({
  types: {
    context: {} as OnboardingMachineContextType,
  },
  actors: {
    fetchUserProfileData: fromPromise(async () => {
      const data = await getUserProfile();
      const termsAccepted =
        !!data?.user_profile?.member_terms_and_conditions?.date_accepted;

      return {
        termsAccepted,
        email: data.email,
        userId: data.user_id,
        displayName: data.user_profile.preferred_first_name,
      };
    }),
  },
  actions: {
    setLocalStorageValue: ({ context }) => {
      localStorage.setItem(
        `${LOCAL_STORAGE_KEYS.FEATURE_HIGHLIGHTS_COMPLETED}_${md5(
          context?.userId,
        )}`,
        'true',
      );
    },
  },
  guards: {
    userAcceptedTerms: ({ event }) => !!event.output.termsAccepted,
    featureHighlightsCompleted: ({ event }) =>
      localStorageItemExists(
        `${LOCAL_STORAGE_KEYS.FEATURE_HIGHLIGHTS_COMPLETED}_${md5(
          event.output.userId,
        )}`,
      ),
  },
}).createMachine({
  id: 'onboarding',
  initial: 'initializing',
  context: {
    termsAccepted: false,
    featureHighlightCompleted: false,
    email: '',
    userId: '',
    displayName: '',
  },
  states: {
    initializing: {
      id: 'initializing',
      initial: 'fetchUserProfileData',
      states: {
        fetchUserProfileData: {
          id: 'fetchUserProfileData',
          invoke: {
            id: 'fetchUserProfileData',
            src: 'fetchUserProfileData',
            onDone: [
              {
                guard: 'userAcceptedTerms',
                target: '#onboardingComplete',
                actions: [
                  // Update context before setting the local storage value. setLocalStorageValue uses context to determine the key.
                  assign(({ event }) => event.output),
                  { type: 'setLocalStorageValue' },
                ],
              },
              {
                guard: 'featureHighlightsCompleted',
                target: '#userSetup',
                actions: assign(({ event }) => event.output),
              },
              {
                target: '#featureHighlights',
                actions: assign(({ event }) => event.output),
              },
            ],
          },
        },
      },
    },
    featureHighlights: {
      id: 'featureHighlights',
      on: {
        featureHighlightsCompleted: {
          target: '#userSetup',
          actions: [
            { type: 'setLocalStorageValue' },
            assign({ featureHighlightCompleted: true }),
          ],
        },
      },
    },
    userSetup: {
      id: 'userSetup',
      initial: 'createProfile',
      states: {
        createProfile: {
          id: 'createProfile',
          on: {
            userConfirmedProfile: {
              target: 'consent',
            },
          },
        },
        consent: {
          id: 'consent',
          on: {
            userAgreedToContinue: {
              target: 'allSet',
            },
          },
        },
        allSet: {
          on: {
            userSelectedContinue: {
              target: '#onboardingComplete',
            },
          },
        },
      },
    },
    onboardingComplete: {
      id: 'onboardingComplete',
      type: 'final',
    },
  },
});

export const OnboardingMachineContext = createActorContext(onboardingMachine);
