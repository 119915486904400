import {
  EVENT_NAME,
  PRODUCT_AREA,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';
import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import {
  HeadingText,
  ParagraphText,
  VisuallyHidden,
  Modal,
  StackLayout,
  Button,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { useLocation } from 'react-router-dom';
import { ROUTE_PATHS } from 'common/constants';
import {
  DETAIL,
  SCREEN_NAMES,
  SUB_PRODUCT_AREA,
} from 'common/constants/analytics';
import { BaseModalContainer } from 'components/modals/common/base-modal.component';
import { ModalActionButton } from 'components/modals/common/modal-action-button.component';

export interface Props {
  url: string;
  onSubmit: () => void;
}
export const SignInIntegrationModal = ({ onSubmit, url }: Props) => {
  const { formatMessage } = useIntl();

  const location = useLocation();

  const productAreaAnalytics = () => {
    const getCarePath = getModulePath(LEAGUE_MODULE_NAMES.careDiscovery);

    if (location.pathname === ROUTE_PATHS.HOME) {
      return PRODUCT_AREA.HOME_SCREEN;
    }
    if (location.pathname.startsWith(getCarePath)) {
      return PRODUCT_AREA.GET_CARE;
    }

    return undefined;
  };

  const trackCTAButtonClick = (detail: string) => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: productAreaAnalytics(),
      sub_product_area: SUB_PRODUCT_AREA.LINKOUT_TILE,
      screen_name: SCREEN_NAMES.LINKOUT_INTERSTITIAL,
      detail,
    });
  };

  return (
    <BaseModalContainer
      maxWidth={381}
      onClose={() => trackCTAButtonClick(DETAIL.CLOSE)}
    >
      <Modal.Title>
        <VisuallyHidden>
          {formatMessage({ id: 'STR_SIGN_IN_ONE_MORE_TIME' })}
        </VisuallyHidden>
      </Modal.Title>
      <HeadingText level="2" size="xl" css={{ textAlign: 'center' }}>
        {formatMessage({ id: 'STR_SIGN_IN_ONE_MORE_TIME' })}
      </HeadingText>
      <ParagraphText>
        {formatMessage({ id: 'STR_SIGN_IN_ONE_MORE_TIME_BODY' })}
      </ParagraphText>
      <StackLayout css={{ width: '100%', marginBlockStart: '$one' }}>
        <Button
          as="a"
          indicator="externalLink"
          width="fillContainer"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            onSubmit();
            trackCTAButtonClick(DETAIL.CONTINUE_TO_MYCHART);
          }}
        >
          {formatMessage({ id: 'STR_CONTINUE_TO_MYCHART' })}
        </Button>
        <ModalActionButton
          actionType="cancel"
          labelId="STR_CANCEL"
          buttonWidth="fillContainer"
          onClick={() => {
            trackCTAButtonClick(DETAIL.CANCEL);
          }}
          cssStyle={{
            color: '$secondaryTextDefault',
            border: 'none',
            marginBlockStart: '$oneAndHalf',
          }}
        />
      </StackLayout>
    </BaseModalContainer>
  );
};
