import { MouseEvent } from 'react';
import {
  Card,
  HeadingText,
  ParagraphText,
  StackLayout,
  useClickableCard,
  Icon,
} from '@leagueplatform/genesis-core';
import { OpensInANewTabMessage } from '@leagueplatform/web-common-components';
import type { NotificationTypes } from 'common/types';
import { NotificationTypeBadge } from 'components/notifications/notification-type-badge.component';
import { InternalOrExternalTextAction } from 'components/notifications/internal-or-external-text-action.component';
import { useTestResultsViewedMutation } from 'hooks/use-test-results-viewed-mutation.hook';
import { useConfigProperty } from '@leagueplatform/core';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';
import {
  PRODUCT_AREA as OVAT_PRODUCT_AREA,
  SCREEN_NAMES,
  SUB_PRODUCT_AREA,
} from 'common/constants/analytics';

export type NotificationCardProps = {
  heading: string;
  body: string;
  notificationType: NotificationTypes;
  badgeText: string;
  actionLink: string;
  isExternalLink: boolean;
  nodeId: string;
  onCardClick?: (event: MouseEvent) => void;
};

export const NotificationCard = ({
  heading,
  body,
  actionLink,
  notificationType,
  badgeText,
  isExternalLink,
  nodeId,
  onCardClick,
}: NotificationCardProps) => {
  const { primaryActionRef, clickAreaRef, onCardMouseDown, onCardMouseUp } =
    useClickableCard();
  const { mutate: setTestResultsViewed } = useTestResultsViewedMutation();

  const isTestResultsCard = Boolean(
    notificationType === 'test' || notificationType === 'testResult',
  );

  const handleLinkCallback = useConfigProperty('core.customMethods.handleLink');

  const productAreaAnalytics = () => {
    if (nodeId.includes('notifications_get_care')) {
      return PRODUCT_AREA.GET_CARE;
    }
    if (nodeId.includes('notifications_homepage')) {
      return PRODUCT_AREA.HOME_SCREEN;
    }
    if (nodeId.startsWith('ovatientNotificationCard_node')) {
      return OVAT_PRODUCT_AREA.NOTIFICATIONS;
    }

    return undefined;
  };

  const trackNotificationCardClick = () => {
    const productArea = productAreaAnalytics();
    if (productArea) {
      trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
        product_area: productArea,
        sub_product_area: SUB_PRODUCT_AREA.NOTIFICATION_CARDS,
        screen_name: SCREEN_NAMES.NOTIFICATION_CARDS,
        detail: notificationType,
      });
    }
  };

  return (
    <Card.Flat
      css={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        boxShadow: '0px 2px 6px 0px #0000001F',
        borderRadius: '8px',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '$onSurfaceBorderSubdued',
        backgroundColor: '$surfaceCardSecondary',
        width: '100%',
      }}
    >
      <Card.ClickArea
        ref={clickAreaRef}
        onCardMouseUp={onCardMouseUp}
        onCardMouseDown={onCardMouseDown}
        imagePosition={{ '@initial': 'end', '@mobile': 'top' }}
        css={{
          flexDirection: 'row',
          alignItems: 'center',
          border: 'none !important',
          paddingInline: '$oneAndHalf',
          paddingBlock: '$oneAndHalf',
          gap: '$quarter',
          height: '100%',
          boxShadow: 'none',
        }}
      >
        <StackLayout css={{ flexGrow: 1, justifyContent: 'center' }}>
          <HeadingText level="3" size="sm">
            <InternalOrExternalTextAction
              isExternalLink={isExternalLink}
              url={actionLink}
              ref={primaryActionRef}
              onClick={(event) => {
                trackNotificationCardClick();
                if (isTestResultsCard) {
                  event.preventDefault();
                  setTestResultsViewed(
                    {},
                    {
                      onSuccess: () => {
                        handleLinkCallback(actionLink, '_blank');
                      },
                    },
                  );
                }
                if (onCardClick) {
                  onCardClick(event);
                }
              }}
            >
              {heading}
            </InternalOrExternalTextAction>
          </HeadingText>
          <ParagraphText
            css={{
              typography: '$bodyTwo',
              marginBlockEnd: '$none',
              color: '$onSurfaceTextSubdued',
            }}
          >
            {body}
            {isExternalLink && <OpensInANewTabMessage />}
          </ParagraphText>
          <NotificationTypeBadge label={badgeText} type={notificationType} />
        </StackLayout>
        {isExternalLink && (
          <StackLayout css={{ flex: 0, justifyContent: 'center' }}>
            <Icon
              icon="interfaceExternalLink"
              size="$one"
              tint="$onSurfaceTextPrimary"
            />
          </StackLayout>
        )}
      </Card.ClickArea>
    </Card.Flat>
  );
};
