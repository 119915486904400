import { forwardRef, Ref, ReactNode } from 'react';
import { TextAction } from '@leagueplatform/genesis-core';
import { Link } from 'react-router-dom-v5-compat';

type InternalOrExternalTextActionProps = {
  children: ReactNode;
  onClick: (event: React.MouseEvent) => void;
  isExternalLink: boolean;
  url: string;
};

const TextActionCSS = {
  display: 'block',
  width: 'fit-content',
  marginBlockEnd: '$none',
  '&&': {
    color: '$onSurfaceTextPrimary',
  },
};

export const InternalOrExternalTextAction = forwardRef(
  (
    {
      children,
      isExternalLink,
      onClick,
      url,
    }: InternalOrExternalTextActionProps,
    ref,
  ) => {
    if (isExternalLink) {
      return (
        <TextAction
          href={url}
          target="_blank"
          css={TextActionCSS}
          ref={ref as Ref<HTMLAnchorElement>}
          onClick={onClick}
        >
          {children}
        </TextAction>
      );
    }
    return (
      <TextAction
        as={Link}
        css={TextActionCSS}
        to={url}
        ref={ref as Ref<HTMLAnchorElement>}
        onClick={onClick}
      >
        {children}
      </TextAction>
    );
  },
);
