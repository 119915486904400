import { lazy } from 'react';

export const LandingPage = lazy(() => import('pages/LandingPage'));

export const HomePage = lazy(() => import('pages/home/home.page'));

export const PersonalInformationPage = lazy(
  () => import('pages/personal-information'),
);

export const CommunicationPreferencesPage = lazy(
  () => import('pages/communication-preferences'),
);
export const AboutPage = lazy(() => import('pages/about/about.page'));

export const CareCollectionsRoutes = lazy(() =>
  import('@leagueplatform/care-collections').then((module) => ({
    default: module.CareCollectionsRoutes,
  })),
);
export const CareDiscoveryRoutes = lazy(() =>
  import('@leagueplatform/care-discovery').then((module) => ({
    default: module.CareDiscoveryRoutes,
  })),
);
export const HealthJourneyRoutes = lazy(() =>
  import('@leagueplatform/health-journey').then((module) => ({
    default: module.HealthJourneyRoutes,
  })),
);
export const HealthNeedPages = lazy(() =>
  import('@leagueplatform/care-collections').then((module) => ({
    default: module.HealthNeedPages,
  })),
);

export const WalletRoutes = lazy(() =>
  import('@leagueplatform/wallet').then((module) => ({
    default: module.WalletRoutes,
  })),
);

export const HealthProfileRoutes = lazy(() =>
  import('@leagueplatform/health-profile').then((module) => ({
    default: module.HealthProfileRoutes,
  })),
);

export const AboutMeRoutes = lazy(() =>
  import('@leagueplatform/about-me').then((module) => ({
    default: module.AboutMeRoutes,
  })),
);

export const OnboardingRoutesWithProvider = lazy(() =>
  import('@leagueplatform/onboarding').then((module) => ({
    default: module.OnboardingRoutesWithProvider,
  })),
);

export const ClaimsRoutes = lazy(() =>
  import('@leagueplatform/claims').then((module) => ({
    default: module.ClaimsRoutes,
  })),
);

export const AchievementsRoutes = lazy(() =>
  import('@leagueplatform/rewards-achievements').then((module) => ({
    default: module.AchievementsRoutes,
  })),
);

export const NotificationsPage = lazy(
  () => import('pages/notifications/notifications.page'),
);

export const NotificationDetailsPage = lazy(
  () => import('pages/notifications/notification-details.page'),
);
