import { useEffect } from 'react';
import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { useLocation } from 'react-router-dom';
import { FullPageLoadingSpinner } from 'components/loaders/full-page-loading-spinner.component';
import { MY_CHART_FIRST_CLICKED_KEY } from 'common/constants';

export const AuthViewLogout = () => {
  useEffect(() => {
    StandaloneAuth.client.logout({
      returnTo: window.location.origin,
    });
    localStorage.removeItem(MY_CHART_FIRST_CLICKED_KEY);
  }, []);

  return <FullPageLoadingSpinner />;
};

export const AuthViewLogin = () => {
  const location = useLocation<{ redirectPath?: string } | undefined>();

  useEffect(() => {
    StandaloneAuth.client.loginWithRedirect({
      appState: {
        redirectPath: location.state?.redirectPath,
      },
    });
  }, [location]);

  return <FullPageLoadingSpinner />;
};
