export const DETAIL = {
  ACKNOWLEDGE_DELETION: 'acknowledge deletion',
  AGREE_AND_CONTINUE: 'agree and continue',
  CANCEL: 'cancel',
  CANCEL_DELETION: 'cancel deletion',
  CLOSE: 'close',
  CONFIRM_DELETION: 'confirm deletion',
  CONTINUE: 'continue',
  CONTINUE_TO_MYCHART: 'continue to mychart',
  EDIT_DISPLAY_NAME: 'edit display name',
  EDIT_EMAIL_ADDRESS: 'edit email address',
  EMAIL_OPT_IN_CHECKBOX: 'email opt-in checkbox',
  LOGIN: 'login',
  OPEN_SETTINGS_MENU: 'open settings menu',
  REGISTER_NOW: 'register now',
  SAVE: 'save',
  SELECT_CTA: 'select CTA',
  TEXT_NOTIFICATION_TOGGLE: 'text notification toggle',
  VIEW_MORE: 'view more',
};

export const PRODUCT_AREA = {
  NOTIFICATIONS: 'notifications',
};

export const SCREEN_NAMES = {
  ABOUT: 'about app overview',
  ALL_SET: 'all set',
  CONSENT: 'consent',
  DELETE_CONFIRMATION: 'deletion confirmation',
  LINKOUT_INTERSTITIAL: 'linkout interstitial',
  LOGIN: 'login',
  NOTIFICATION_CARDS: 'notification card',
  NOTIFICATION_DETAIL_PAGE: 'notification detail page',
};

export const SUB_PRODUCT_AREA = {
  ALL_SET: 'all set',
  CONSENT: 'consent',
  LINKOUT_TILE: 'linkout tile',
  NOTIFICATION_CARDS: 'notification card',
  SIGN_IN_INTRO: 'sign-in intro',
};
