import { GDSStyleObject } from '@leagueplatform/genesis-core';

export const NAV_BAR_HEIGHT = 56;
export const RESPONSIVE_NAV_BAR_HEIGHT = 60;
export const MAX_CONTENT_WIDTH = 952;
export const IMAGE_MESSAGE_ICON_WIDTH = 176;

export const SDK_CSS_OVERRIDES: GDSStyleObject = {
  '.badge-progress-wrapper > div > div > div': {
    backgroundColor: '$interactiveActionDisabled',
  },

  '.toast-wrapper': {
    backgroundColor: '$interactiveActionDisabled',
  },

  // OVAT-1747: prevent activity close button from hiding under the top bar
  '.health-activity-remove, .health-activity-close': {
    top: '$five',
  },
};
