import {
  HeadingText,
  ParagraphText,
  ImageMessage,
  Modal,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import errorIcon from 'assets/error-icon.svg';
import { BaseModalContainer } from 'components/modals/common/base-modal.component';
import { IMAGE_MESSAGE_ICON_WIDTH } from 'common/ui';
import { DETAIL, SCREEN_NAMES } from 'common/constants/analytics';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hook';
import { ModalActionButton } from './common/modal-action-button.component';

export const AccountDeletedModal = () => {
  const { formatMessage } = useIntl();
  const isBelowTablet = useIsBelowTablet();

  const trackConfirmationClick = () => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.SETTINGS,
      screen_name: SCREEN_NAMES.DELETE_CONFIRMATION,
      detail: DETAIL.ACKNOWLEDGE_DELETION,
    });
  };

  return (
    <BaseModalContainer>
      <ImageMessage
        image={errorIcon}
        imageWidth={IMAGE_MESSAGE_ICON_WIDTH}
        css={{
          paddingBlockStart: '$one',
          paddingBlockEnd: '$two',
          textAlign: isBelowTablet ? 'center' : 'left',
        }}
      >
        <Modal.Title>
          <HeadingText level="2" size="lg" css={{ marginBlockEnd: '$one' }}>
            {formatMessage({ id: 'STR_ACCOUNT_DELETED_TITLE' })}
          </HeadingText>
        </Modal.Title>
        <ParagraphText>
          {formatMessage({ id: 'STR_ACCOUNT_DELETED_BODY_1' })}
        </ParagraphText>
        <ParagraphText>
          {formatMessage({ id: 'STR_ACCOUNT_DELETED_BODY_2' })}
        </ParagraphText>
      </ImageMessage>
      <ModalActionButton
        actionType="submit"
        labelId="STR_I_UNDERSTAND"
        onClick={trackConfirmationClick}
        cssStyle={{
          background: '$interactiveActionPrimary',
          alignSelf: 'flex-end',
        }}
      />
    </BaseModalContainer>
  );
};
